import WebServiceRequest from "../Api/WebServiceRequest";

class GetAllComments extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Comment/GetAll");
  }
  setRequestParam(data) {
    super.setRequestParam(data);
  }
}

class ConfirmComments extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Comment/ConfirmBy");
  }
  setRequestParamDataObj(data) {
    super.setRequestParamDataObj(data);
  }
}

export { GetAllComments, ConfirmComments };
