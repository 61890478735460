<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <div class="mb-3 w-100 d-flex align-items-center">
        <h1>لیست دیدگاه ها</h1>
      </div>
      <div class="w-100">
        <p>فیلتر ها</p>
        <div class="d-flex align-items-center justify-content-end w-100">
          <b-form-group
            class="d-inline-block w-100"
            label="وضعیت نظر"
            label-for="status"
          >
            <v-select
              id="status"
              label="name"
              v-model="confirmed"
              :options="commentStatusOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              class="per-page-selector d-inline-block w-50"
            />
          </b-form-group>
          <b-button variant="primary" @click="setFilters"> اعمال </b-button>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end my-2">
        <b-button variant="primary" @click="selectAllComments"
          >انتخاب همه</b-button
        >
      </div>
      <b-row>
        <b-col cols="12">
          <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="commentsList"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
          >
            <template #cell(isConfirmed)="data">
              <b-badge
                :variant="handleIsConfirmedChipColor(data.item.isConfirmed)"
              >
                {{ data.item.isConfirmed ? "تایید شده" : "تایید نشده" }}
              </b-badge>
            </template>
            <template #cell(actions)="data">
              <b-form-checkbox
                v-model="selectedToConfirm"
                ref="selectComment"
                :value="data.item.commentId"
              ></b-form-checkbox>
            </template>
          </b-table>
        </b-col>
        <b-col cols="12">
          <div class="w-100 d-flex align-items-center justify-content-end">
            <b-button variant="primary" @click="confirmComments">
              تایید نظر ها
            </b-button>
          </div>
        </b-col>
        <b-col cols="12">
          <!-- pagination -->
          <div class="my-2 d-flex align-items-center justify-content-center">
            <b-pagination
              v-model="pageNumber"
              :total-rows="totalCount"
              :per-page="count"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  style="transform: rotate(180deg)"
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>
<script>
export default {
  title: "دیدگاه ها",
  data() {
    return {
      isLoading: false,
      count: 10,
      pageNumber: 1,
      searchCommand: null,
      confirmed: null,
      totalCount: null,
      commentsList: null,
      selectedToConfirm: [],
      confirmStatus: null,
      commentStatusOptions: [
        {
          name: "همه",
          value: null,
        },
        {
          name: "تایید شده",
          value: true,
        },
        {
          name: "تایید نشده",
          value: false,
        },
      ],
      myTableColumns: [
        {
          key: "commentId",
          label: "شناسه دیدگاه",
        },
        {
          key: "message",
          label: "متن دیدگاه",
        },
        {
          key: "isConfirmed",
          label: "وضعیت دیدگاه",
        },
        {
          key: "email",
          label: "نام کاربر",
        },
        {
          key: "actions",
          label: "تایید دیدگاه",
        },
      ],
    };
  },
  mounted() {
    this.getAllComments();
  },
  watch: {
    pageNumber: {
      handler(val) {
        this.getAllComments();
      },
    },
  },
  methods: {
    async confirmComments() {
      try {
        this.isLoading = true;
        let _this = this;
        let confirmComments = new ConfirmComments(_this);
        confirmComments.setRequestParamDataObj(_this.selectedToConfirm);
        await confirmComments.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `نظر ها با موفقیت تایید شدند.`,
              },
            });
            _this.getAllComments();
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    setFilters() {
      this.commentsList = [];
      this.getAllComments();
    },
    handleIsConfirmedChipColor(isConfrimed) {
      if (isConfrimed) {
        return "success";
      } else {
        return "danger";
      }
    },
    selectAllComments() {
      if (this.selectedToConfirm?.length < this.commentsList?.length) {
        this.commentsList.forEach((comment) => {
          this.selectedToConfirm.push(comment.commentId);
        });
      } else {
        this.selectedToConfirm = [];
      }
    },
    async getAllComments() {
      try {
        this.isLoading = true;
        let reqParams = {
          count: this.count,
          pageNumber: this.pageNumber,
        };
        if (this.confirmed) {
          reqParams["confirmed"] = this.confirmed;
        }
        if (this.searchCommand) {
          reqParams["searchCommand"] = this.searchCommand;
        }
        let _this = this;
        let getAllComments = new GetAllComments(_this);
        getAllComments.setRequestParam(reqParams);
        await getAllComments.fetch((response) => {
          if (response.isSuccess) {
            _this.totalCount = response.data.commentsCount;
            _this.commentsList = response.data.comments;
            console.log(_this.commentsList);
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BContainer,
    BPagination,
    BFormRow,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BForm,
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BBadge,
    BFormTextarea,
    BOverlay,
    BFormSelect,
    vSelect,
  },
};
import { GetAllComments, ConfirmComments } from "@/libs/Api/Comment";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BBadge,
  BFormTextarea,
  BOverlay,
  BFormSelect,
} from "bootstrap-vue";
</script>
